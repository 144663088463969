import * as Config from '@/config'
import StructureFactory from '@/components/structures/structureFactory'
import Image from '@/components/structures/image'
import { deepEqual } from '@/components/utils/common'

export default {
  props: {
    value: [Array, Object],
    url: String,
    maxPhotoCount: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      numberOfThreads: 5,
      uploadFieldName: 'photos',
      isUploading: false,
      fileSizeError: [],
      images: this.value,
      imageObjectName: null,
      imagesType: 'Array'
    }
  },
  computed: {
    uploadedFiles () {
      return this.images
    },
    filesSizeErrorMessage () {
      return 'Файлы ' + this.fileSizeError.join(', ') + ' превышают максимальный размер ' + this.bytesToSize(Config.maxImageSize)
    },
    canUploadImages () {
      let canUpload = true
      if (this.maxPhotoCount !== null) {
        canUpload = this.maxPhotoCount - this.images.length
      }
      return canUpload
    }
  },
  watch: {
    value: {
      handler () {
        if (!deepEqual(this.images, this.value)) {
          this.images = this.value
        }
      },
      deep: true
    }
  },
  methods: {
    async filesChange (fieldName, fileList) {
      this.fileSizeError = []
      fileList = fileList.target.files
      if (fileList.length) {
        this.isUploading = true
        let uploadList = []
        const imagesToUploadCount = this.imagesToUploadCount(fileList.length)
        for (let i = 0; i < imagesToUploadCount; i++) {
          if (fileList[i].size && fileList[i].size > Config.maxImageSize) {
            this.fileSizeError.push(fileList[i].name)
          } else {
            uploadList.push(fileList[i])
            this.createImagesStructure()
          }
        }

        if (this.fileSizeError.length) {
          await this.$store.dispatch('systemMessages/error', this.filesSizeErrorMessage)
        }

        await this.parallelUpload(uploadList)

        this.$emit('input', this.images)
        this.isUploading = false
      }
    },
    async parallelUpload (list) {
      for (let thread of this.createParallelQueue(list)) {
        await Promise.all(thread.map(this.save))
      }
      return true
    },
    createParallelQueue (list) {
      return list.reduce((prev, cur, index) => {
        const innerIndex = Math.floor(index / this.numberOfThreads)
        if (prev.length === innerIndex) {
          prev.push([cur])
        } else {
          prev[innerIndex].push(cur)
        }
        return prev
      }, [])
    },
    async save (data) {
      let formData = new FormData()
      formData.append('files[0]', data)
      const response = await this.$store.dispatch('server/save', { url: this.url, data: formData })
      if (response) {
        this.processResponse(response)
      }
    },
    processResponse (response) {
      if (this.imagesType === 'Array') {
        let imageIndex = this.images.findIndex(createdImage => !createdImage.image.id || createdImage.image.id === response[0].id)
        if (imageIndex !== null) {
          this.images[imageIndex].image = response[0]
        }
      } else if (this.imagesType === 'Object') {
        if (this.imageObjectName) {
          this.images.image = response[0]
        } else {
          this.images = response[0]
        }
      } else {
        throw new Error('Невозможно обновить структуру хранения изображений')
      }
    },
    handleRemove (index) {
      this.images.splice(index, 1)
    },
    imagesToUploadCount (fileListCount) {
      let count = 0
      if (this.images === null || !Array.isArray(this.images)) {
        count = 1
      } else {
        let emptySlotsCount = this.maxPhotoCount - this.images.length
        if (this.maxPhotoCount === null || emptySlotsCount >= fileListCount) {
          count = fileListCount
        } else {
          count = emptySlotsCount
          this.$store.dispatch('systemMessages/error', 'Превышено максимальное количество загружаемых фото')
        }
      }

      return count
    },
    createImagesStructure () {
      let imageObject = null
      if (this.imageObjectName) {
        imageObject = new StructureFactory(this.imageObjectName, null)
      } else {
        imageObject = new Image()
      }
      if (this.imagesType === 'Array') {
        this.images.push(imageObject)
      } else if (this.imagesType === 'Object') {
        this.images = imageObject
      } else {
        throw new Error('Невозможно создать структуру хранения изображений')
      }
    }
  }
}
