<template>
  <v-card
    outlined
    class="mt-1"
  >
    <v-card-text>
      <!--<v-alert
        border="left"
        type="warning"
        elevation="2"
        dismissible
        icon="mdi-bullhorn"
      >
        <p style="font-size: 14px">Укажите реальную цену объекта. Занижение цены негативно скажется на
          доверии.</p>
      </v-alert>-->
      <div class="width-30 d-flex mt-10">
        <div class="red--text pt-3 pr-2 title">*</div>
        <v-text-field
          outlined
          dense
          :value="formatPriceInput(price)"
          @input="$emit('update:price', reformatPrice($event))"
          @focus="$event.target.select()"
          label="Цена"
          :error-messages="errorMessages"
        ></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'Price',
  props: {
    price: Number,
    errorMessages: Array
  }
}
</script>

<style scoped>

</style>
