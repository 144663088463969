<template>
  <div>
    <div
      class="photo-building"
      v-if="value"
    >
      <ImageView
        :src="imageSrc(images.image, true)"
        :mx-photo-count="1"
      />
    </div>
    <div v-if="!images || canChangeImage">
      <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-bullhorn"
      >
        <p style="font-size: 14px">Если фотография дома отсутствует, можете загрузить свою. Красивая
          фотография
          дома увеличивает просмотры Вашего объявления потенциальными покупателями на 20%.</p>
      </v-alert>
      <v-btn
        color="primary"
        :loading="isUploading"
      >
        <v-icon>mdi-upload</v-icon>
        Загрузить фото дома
        <input
          :disabled="isUploading"
          :name="uploadFieldName"
          @change="filesChange($event.target.name, $event)"
          accept="image/*"
          class="input-file"
          type="file"
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
import imageUpload from '@/components/mixins/imageUpload'
import ImageView from '@/components/views/Common/ImageView'
import image from '@/components/mixins/image'

export default {
  mixins: [imageUpload, image],
  name: 'UploadBuildingImage',
  components: { ImageView },
  data () {
    return {
      imageObjectName: 'relatedImage',
      canChangeImage: false,
      imagesType: 'Object'
    }
  },
  watch: {
    // оставляем кнопку загрузки в случае успешной загрузки фото для возможности изменить фотку
    isUploading (val) {
      if (!val) {
        this.canChangeImage = true
      }
    }
  }
}
</script>

<style scoped>

</style>
