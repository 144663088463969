import Advert from '@/components/structures/advert'
import Building from '@/components/structures/building'
import Street from '@/components/structures/street'
import Phone from '@/components/structures/phone'
import RelatedImage from '@/components/structures/relatedImage'

class StructureFactory {
  constructor (name, data = null) {
    switch (name) {
      case 'advert':
        return new Advert()
      case 'building':
        return new Building()
      case 'street' :
        return [new Street()]
      case 'phone' :
        return new Phone()
      case 'relatedImage' :
        return new RelatedImage(data)
    }
  }
}

export default StructureFactory
