import * as Config from '@/config'

export default {
  computed: {
    phoneStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.status')
    },
    ownerStatuses () {
      return this.$store.getters['serviceData/optionsList']('phone.owner_status')
    },
    phoneMask () {
      return Config.phoneMask
    }
  }
}
