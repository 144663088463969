<template>
  <v-card class="pa-3">
    <v-card-text>
      <v-text-field
        label="Телефон"
        outlined
        dense
        :value="maskedPhone"
        @input="handleInput($event)"
        v-mask="{mask: mask, unmaskedVar: 'clearValue'}"
        :error-messages="checkErrors(`phone.phone.phone`)"
        @focus="$event.target.select()"
      ></v-text-field>
      <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-bullhorn"
      >Укажите какое отношение данный телефон имеете к данному объекту
      </v-alert>
      <v-textarea
        outlined
        dense
        label="Комментарий"
        v-model="phone.description"
      >
      </v-textarea>
      <div class="text-right">
        <v-btn
          class="primary"
          @click="handleSave"
        >
          Сохранить
        </v-btn>
      </div>
      <div class="text-right">
        <v-btn
          class="primary"
          @click="$emit('close')"
        >
          Отмена
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import phoneInput from '@/components/mixins/phoneInput'
import validationErrors from '@/components/mixins/validationErrors'
import { minLength, required } from 'vuelidate/lib/validators'
import { cleanClone } from '@/components/utils/common'

export default {
  mixins: [phoneInput, validationErrors],
  name: 'AdditionPhoneDetail',
  validations: {
    phone: {
      phone: {
        phone: {
          required,
          minLength: minLength(11)
        }
      }
    }
  },
  methods: {
    handleSave () {
      this.$v.$touch()
      if (!this.$v.phone.phone.phone.$anyError) {
        this.$emit('change', this.phone)
        this.phone = cleanClone(this.value)
        this.clearValue = null
        this.maskedPhone = null
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
