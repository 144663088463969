<template>
  <div>
    <Table
      v-if="items.count !== 0"
      @input="handleOptionsInput($event)"
      :loading="isLoading"
      :items="items.models"
      :items-length="items.count"
      @detail="$emit('detail', $event)"
    />
    <div
      v-else
      class="no-adverts d-flex justify-center align-center"
    >
      <v-alert
        color="primary"
        border="left"
        elevation="1"
        colored-border
        type="warning"
      > У вас нет объявлений,
      </v-alert>
    </div>
  </div>
</template>
<script>
import headers from './List/header'
import list from '@/components/mixins/list'
import Table from '@/components/views/Account/MyAdvert/List/Table'

export default {
  mixins: [list],
  name: 'List',
  components: { Table },
  data () {
    return {
      headers: headers,
      query: {
        filter: {
          own: true
        },
        pagination: {},
        sort: {}
      },
      apiUrl: '/account/sale'
    }
  },
  methods: {
    handleOptionsInput (data) {
      if (data) {
        this.query.sort = data.sort
        this.query.pagination = data.pagination
      }
    },
    async handleQueryChange () {
      return this.fillData()
    }
  }
}
</script>

<style scoped>
  .no-adverts {
    height: 50vh;
  }
</style>
