export default {
  props: {
    items: Array,
    itemsLength: Number,
    loading: Boolean
  },
  methods: {
    async detail (id) {
      return this.$router.push({ name: 'myAdvertsDetail', params: { id } })
    },
    handleOptionsUpdate (data) {
      let result = {
        pagination: {
          perPage: data.itemsPerPage,
          pageNumber: data.page
        },
        sort: {}
      }
      data.sortBy.forEach((item, index) => {
        result.sort[item] = data.sortDesc[index] ? 'DESC' : 'ASC'
      })
      this.$emit('input', result)
    }
  }
}
