export default [
  {
    text: '#',
    align: 'left',
    sortable: true,
    value: 'id'
  },
  {
    text: 'Адрес',
    align: 'left',
    sortable: true,
    value: 'address'
  },
  {
    text: 'Кол-во комнат',
    align: 'center',
    sortable: true,
    value: 'room_count'
  },
  {
    text: 'Общая площадь',
    align: 'left',
    sortable: true,
    value: 'area_common'
  },
  {
    text: 'Тип жилья',
    align: 'left',
    sortable: true,
    value: 'flat_type'
  },
  {
    text: 'Э-ж',
    align: 'left',
    sortable: true,
    value: 'floor'
  },
  {
    text: 'Э-ть',
    align: 'left',
    sortable: true,
    value: 'total_floors'
  },
  {
    text: 'Мат. стен',
    align: 'left',
    sortable: true,
    value: 'buildingMaterial'
  },
  {
    text: 'Год',
    align: 'left',
    sortable: true,
    value: 'buildYear'
  },
  {
    text: 'Состояние',
    align: 'left',
    sortable: true,
    value: 'flat_status'
  },
  {
    text: 'Цена',
    align: 'left',
    sortable: true,
    value: 'price'
  },
  {
    text: 'Создано',
    align: 'left',
    sortable: true,
    value: 'created_at'
  },
  {
    text: 'Опубликовано',
    align: 'left',
    sortable: true,
    value: 'is_published'
  }
]
