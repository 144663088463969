<template>
  <div>
    <!--    <v-card-text>-->
    <!--      <h2 class="mb-5">Об объекте</h2>
          <div class="width-50 d-flex">
            <div class="red&#45;&#45;text pt-3 pr-2 title">*</div>
            <v-select
              dense
              outlined
              :value="flatType"
              @input="$emit('update:flatType', $event)"
              :items="flatTypeItems"
              item-value="id"
              item-text="value"
              label="Тип жилья"
              :error-messages="checkErrors('detail.flat_type')"
            ></v-select>
          </div>-->
    <!--        <div class="red&#45;&#45;text pt-3 pr-2 title">*</div>-->
    <v-text-field
      style="max-width: 370px"
      outlined
      dense
      :value="roomCount"
      @input="$emit('update:roomCount', $event)"
      label="Количество комнат"
      type="number"
      :error-messages="checkErrors('detail.room_count')"
      @focus="$event.target.select()"
    ></v-text-field>
    <!--        <div class="red&#45;&#45;text pt-3 pr-2 title">*</div>-->
    <v-text-field
      style="max-width: 370px"
      outlined
      dense
      :value="floor"
      @input="$emit('update:floor', $event)"
      label="Этаж"
      type="number"
      :error-messages="checkErrors('detail.floor')"
      @focus="$event.target.select()"
    ></v-text-field>
    <!--        <div class="red&#45;&#45;text pt-3 pr-2 title">*</div>-->
    <v-text-field
      style="max-width: 370px"
      outlined
      dense
      :value="areaCommon"
      @input="$emit('update:areaCommon', $event)"
      label="Общая площадь м2"
      type="number"
      :error-messages="checkErrors('detail.area_common')"
      @focus="$event.target.select()"
    ></v-text-field>
    <v-text-field
      style="max-width: 370px"
      outlined
      dense
      :value="formatPriceInput(price)"
      @input="$emit('update:price', reformatPrice($event))"
      @focus="$event.target.select()"
      label="Цена"
      :error-messages="checkErrors('detail.price')"
    ></v-text-field>
    <!--      <div class="width-30 d-flex">
            <v-text-field
              outlined
              dense
              :value="areaKitchen"
              @input="$emit('update:areaKitchen', $event)"
              label="Площадь Кухни м2"
              type="number"
              @focus="$event.target.select()"
            ></v-text-field>
          </div>
          <div class="width-30 d-flex">
            <v-text-field
              outlined
              dense
              :value="areaLiving"
              @input="$emit('update:areaLiving', $event)"
              label="Жилая площадь м2"
              type="number"
              @focus="$event.target.select()"
            ></v-text-field>
          </div>
          <div class="width-30 d-flex">
            <v-text-field
              outlined
              dense
              :value="planingType"
              @input="$emit('update:planingType', $event)"
              label="Планировка"
              @focus="$event.target.select()"
            ></v-text-field>
          </div>
          <div class="width-30 d-flex">
            <v-select
              dense
              outlined
              :value="flatStatus"
              @input="$emit('update:flatStatus', $event)"
              :items="flatStatusItemsWithEmpty"
              item-value="id"
              item-text="value"
              label="Состояние"
            ></v-select>
          </div>-->
    <!--<div class="width-30 d-flex">
      <v-select
        dense
        outlined
        :value="exclusiveStatus"
        @input="$emit('update:exclusiveStatus', $event)"
        :items="exclusiveStatusItems"
        item-value="id"
        item-text="value"
        label="Особености"
      ></v-select>
    </div>-->
    <!--    </v-card-text>-->
    <v-card-text class="pt-0 pl-0">
      <p style="max-width: 360px;">Расскажите о вашем объявлении: </p>
      <div class="width-70">
        <v-textarea
          outlined
          placeholder="Это описание будет опубликовано после модерации объявления"
          :value="descriptionPublic"
          @input="$emit('update:descriptionPublic', $event)"
          label="Описание объекта"
          hide-details
        ></v-textarea>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import validationErrors from '@/components/mixins/validationErrors'
import serviceDataComputeds from '@/components/mixins/serviceDataComputed'

export default {
  mixins: [formatters, validationErrors, serviceDataComputeds],
  name: 'AdvertInfo',
  props: [
    'flatType',
    'flatNumber',
    'roomCount',
    'floor',
    'flatStatus',
    'priceHistory',
    'areaCommon',
    'price',
    'areaLiving',
    'areaKitchen',
    'planingType',
    'rawData',
    'descriptionPublic',
    'exclusiveStatus'
  ],
  inject: ['$v']
}
</script>

<style scoped>

</style>
