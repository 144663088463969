<template>
  <v-card class="elevation-0">
    <v-card-text class="pl-0 pt-0">
      <div class="mt-1 d-flex">
        <v-btn
          color="darkGreen"
          rounded
          depressed
          height="45"
          class="mr-1 white--text"
          @click="$emit('save')"
          :loading="loading"
        >
          {{ textSave || 'Сохранить' }}
        </v-btn>
        <v-btn
          color="second"
          rounded
          depressed
          height="45"
          @click="dialog = true"
          :loading="loading"
          v-if="isDelete"
        >Удалить
        </v-btn>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="290px"
        >
          <v-card>
            <v-card-title>Внимание</v-card-title>
            <v-card-text> Вы действительно хотите удалить это объявление ?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Отмена
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="handleDelete"
                :loading="loading"
              >
                Удалить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Save',
  props: {
    loading: Boolean,
    isDelete: {
      default: true,
      type: Boolean
    },
    textSave: String
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    loading (val) {
      if (!val) {
        this.dialog = false
      }
    }
  },
  methods: {
    handleDelete () {
      this.$emit('delete')
    }
  }
}
</script>

<style scoped>

</style>
