<template>
  <v-card
    outlined
    class="mt-1"
  >
    <v-card-text>
      <h2 class="mb-4">Поиск дома по адресу</h2>
      <div
        v-if="!localValues.address.id"
        class="subtitle-1"
      > Начните вводить адрес, если его НЕ будет в нашей базе, то заполните данные ниже вручную
      </div>
      <div class="d-flex flex-wrap mt-5">
        <div class="pr-2 flex-grow-1">
          <Autocomplete
            :value="localValues.address.street"
            @input="handleStreet($event)"
            item-value="streetId"
            item-text="name"
            placeholder="Улица"
            url="/street/autocomplete"
            :chars-to-search="2"
            clearable
            outlined
            style="flex-grow: 1"
          >
            <template v-slot:appendItem>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    @click="handleCreateBuilding"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Добавить улицу</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </Autocomplete>
        </div>
        <div>
          <v-autocomplete
            :value="localValues.address"
            @input="handleBuildingNumber($event)"
            :items="buildingNumbers"
            label="Дом"
            item-value="id"
            item-text="building_number"
            :loading="buildingLoading"
            :disabled="!localValues.address.street.id"
            @focus="fillBuildingNumbers"
            return-object
            :cache-items="false"
            style="position: relative"
            clearable
            outlined
          >
            <template v-slot:append-item>
              <v-list>
                <v-list-item-group class="primary">
                  <v-list-item
                    @click="handleCreateBuilding"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Добавить дом</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div>
        <v-alert
          v-if="localValues.address.id"
          type="success"
        >
          Этот адрес есть в нашей базе мы заполнили данные дома автоматически,
          <a
            style="color: white; text-decoration: underline"
            @click="handleShowBuilding"
          >{{show ? 'скрыть данные' : 'показать данные'}}</a>
        </v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Autocomplete from '@/components/views/Common/Autocomplete'
import { cleanClone } from '@/components/utils/common'
import serviceDataComputeds from '@/components/mixins/serviceDataComputed'
import Address from '@/components/structures/address'

export default {
  mixins: [serviceDataComputeds],
  name: 'AddressSearch',
  components: { Autocomplete },
  props: {
    address: Object,
    show: Boolean
  },
  data () {
    return {
      buildingLoading: false,
      buildingNumberList: cleanClone([this.address]),
      localValues: {
        address: cleanClone(this.address)
      }
    }
  },
  watch: {
    'localValues.address': {
      handler () {
        if (this.address.building_id !== this.localValues.address.building_id) {
          this.$emit('update:address', cleanClone(this.localValues.address))
        }
      },
      deep: true
    },
    address: {
      handler () {
        if (this.address.building_id && this.address.building_id !== this.localValues.address.building_id) {
          this.localValues.address = cleanClone(this.address)
          this.buildingNumberList = cleanClone([this.address])
        }
      },
      deep: true
    }
  },
  methods: {
    async fillBuildingNumbers () {
      if (this.localValues.address.street) {
        this.buildingLoading = true
        const response = await this.$store.dispatch('server/get', { url: '/address/building-numbers?streetId=' + this.localValues.address.street.id })
        if (response) {
          this.buildingNumberList = response
        }
        this.buildingLoading = false
      }
    },
    handleBuildingNumber (data) {
      if (data) {
        this.localValues.address.id = data.id
        this.localValues.address.building_number = data.building_number
        this.localValues.address.building_id = data.building_id
      } else {
        this.clearBuildingNumber()
      }
    },
    handleStreet (data) {
      this.localValues.address.street = data
      if (!(data && this.address.street && this.address.street.id === this.localValues.address.street.id)) {
        this.clearBuildingNumber()
        this.buildingNumberList = []
      }
    },
    clearBuildingNumber () {
      this.localValues.address.id = null
      this.localValues.address.building_number = null
      this.localValues.address.building_id = null
    },
    handleCreateBuilding () {
      this.handleStreet('')
      this.$emit('update:show', !this.show)
      this.$emit('update:address', new Address())
      this.$vuetify.goTo('#buildingInfo', {
        offset: 100,
        easing: 'easeInOutCubic'
      })
    },
    handleShowBuilding () {
      this.$emit('update:show', !this.show)
      this.$vuetify.goTo('#buildingInfo', {
        offset: 100,
        easing: 'easeInOutCubic'
      })
    }
  }
}
</script>

<style scoped>

</style>
