<template>
  <div class="mb-5 mt-3 add-phones">
    <v-alert
      v-if="localPhones.length"
      color="blue-grey lighten-4"
      dense
      class="width-50"
    >
      <v-row
        v-for="(advertPhone, index) in localPhones"
        :key="localPhones.length+''+index"
      >
        <v-col
          cols="9"
          md="9"
        >
          <small class="label-text">Дополнительный телефон</small>
          <div class="title">{{maskValue(advertPhone.phone.phone, phoneMask)}}</div>
          <p style="font-size: 14px">{{advertPhone.description}}</p>
        </v-col>
        <v-col
          cols="3"
          md="3"
          class="d-flex align-center"
        >
          <v-menu
            :close-on-content-click="false"
            offset-x
            max-width="350"
            ref="edit"
            internal-activator
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                icon
                v-on="on"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="obj">
              <AdditionPhoneDetail
                :value="advertPhone"
                :index="index"
                @change="handlePhoneChange(index, $event)"
                :mask="phoneMask"
                @close="handleInternalClose('edit', index)"
              />
            </template>
          </v-menu>
          <v-btn
            v-if="localPhones.length > 1"
            small
            icon
            @click="handleRemovePhone(index)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-menu
      :close-on-content-click="false"
      offset-x
      max-width="350"
      ref="create"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="primary"
          small
        >
          <v-icon small>mdi-plus</v-icon>
          Добавить телефон
        </v-btn>
      </template>
      <AdditionPhoneDetail
        :value="newValue"
        @change="handlePhoneChange(null, $event)"
        :can-remove="localPhones.length > 1"
        :mask="phoneMask"
        @close="handleInternalClose('create')"
      />
    </v-menu>
  </div>
</template>

<script>
import AdditionPhoneDetail from './AdditionPhoneList/AdditionPhoneDetail'
import formatters from '@/components/mixins/formatters'
import phoneComputed from '@/components/mixins/phoneComputed'
import AdvertPhone from '@/components/structures/advertPhone'
import phoneList from '@/components/mixins/phoneList'

export default {
  mixins: [formatters, phoneComputed, phoneList],
  name: 'AdditionPhoneList',
  components: { AdditionPhoneDetail },
  computed: {
    newValue () {
      return new AdvertPhone()
    }
  },
  methods: {
    // note: закрывает меню из ребенка без использования v-model
    handleInternalClose (name, index = null) {
      let ref = this.$refs[name]
      if (index !== null) {
        ref = ref[index]
      }
      ref.isActive = false
    }
  }
}
</script>

<style scoped>
  .label-text {
    color: #333;
    font-size: 10px;
    position: absolute;
    left: 2px;
    top: -14px;
  }
</style>
