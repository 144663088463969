<template>
  <div>
    <div
      v-if="!src"
      class="b-loader-photo flex-column"
    >
      <small>Загрузка фото</small>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="2"
      ></v-progress-linear>
    </div>
    <v-img
      v-else
      v-on="on"
      :width="width"
      :height="height"
      :min-width="minWidth"
      :min-height="minHeight"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :src="src"
      :class="imgClass"
      @load="handleLoad"
      contain
    >
      <!--прелоадер между заливкой фото на сервер и загрузкой на клиента-->
      <template v-slot:default>
        <div
          v-if="loading"
          class="b-loader-photo"
        >
          <v-progress-circular
            indeterminate
            width="2"
            color="deep-purple accent-4"
          ></v-progress-circular>
        </div>
      </template>
      <!--прелоадер для загрузки фото на клиента-->
      <template v-slot:placeholder>
        <div
          v-if="!loading"
          class="b-loader-photo"
        >
          <v-progress-circular
            indeterminate
            width="2"
            color="deep-purple accent-4"
          ></v-progress-circular>
        </div>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'ImageView',
  props: {
    on: Object,
    src: String,
    maxWidth: String,
    maxHeight: String,
    width: Number,
    height: Number,
    minWidth: Number,
    minHeight: Number,
    imgClass: String
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    loading () {
      return this.src && this.isLoading
    }
  },
  methods: {
    handleLoad () {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
  .b-loader-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>
