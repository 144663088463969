<template>
  <div id="buildingInfo">
    <v-expand-transition>
      <v-card
        outlined
        class="mt-1"
        v-if="isLoading"
      >
        <v-progress-circular
          color="pimary"
          indeterminate
        ></v-progress-circular>
      </v-card>
      <v-card
        v-else
        outlined
        class="mt-1"
        v-show="show"
      >
        <v-card-text>
          <v-alert
            border="left"
            type="warning"
            elevation="2"
            icon="mdi-bullhorn"
            dismissible
            v-if="!value.mainBuildingAddress.id"
          >
            Подробное описание дома увеличивает интерес к вашему обявлению на <b>20%</b>
          </v-alert>
          <h2>Описание Дома</h2>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <div class="width-70 d-flex">
                <v-text-field
                  outlined
                  dense
                  label="Улица"
                  :value="value.mainBuildingAddress.street.name"
                  @input="handleStreetNameChange($event)"
                  :disabled="!!value.mainBuildingAddress.id"
                  :error-messages="checkErrors('detail.building.mainBuildingAddress.street.name')"
                  @focus="$event.target.select()"
                ></v-text-field>
              </div>
              <div class="width-30 d-flex">
                <v-text-field
                  outlined
                  dense
                  label="Дом"
                  :value="value.mainBuildingAddress.building_number"
                  @input="handleBuildingNumberChange($event)"
                  :disabled="!!value.mainBuildingAddress.id"
                  :error-messages="checkErrors('detail.building.mainBuildingAddress.building_number')"
                  @focus="$event.target.select()"
                ></v-text-field>
              </div>
              <div class="width-70 d-flex">
                <v-select
                  dense
                  outlined
                  :value="value.building_type"
                  @input="$emit('input', {...value, building_type: $event})"
                  :items="buildingTypeItems"
                  item-value="id"
                  item-text="value"
                  label="Тип здания"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-select>
              </div>
              <div class="width-30 d-flex">
                <v-text-field
                  outlined
                  dense
                  :value="value.total_floors"
                  @input="$emit('input', {...value, total_floors: $event})"
                  label="Этажность"
                  type="number"
                  :disabled="!!value.mainBuildingAddress.id"
                  @focus="$event.target.select()"
                ></v-text-field>
              </div>
              <div class="width-30 d-flex">
                <v-checkbox
                  :value="value.is_ready"
                  @input="$emit('input', {...value, is_ready: $event})"
                  :false-value="0"
                  :true-value="1"
                  label="Дом сдан"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-checkbox>
              </div>
              <div class="width-30 d-flex">
                <v-text-field
                  outlined
                  dense
                  :value="value.build_year"
                  @input="$emit('input', {...value, build_year: $event})"
                  label="Год постройки"
                  type="number"
                  :disabled="!!value.mainBuildingAddress.id"
                  @focus="$event.target.select()"
                ></v-text-field>
              </div>
              <div class="width-70 d-flex">
                <v-select
                  dense
                  outlined
                  :value="value.building_material"
                  @input="$emit('input', {...value, building_material: $event})"
                  :items="buildingMaterialItems"
                  item-value="id"
                  item-text="value"
                  label="Материал стен"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-select>
              </div>
              <div class="width-70 d-flex">
                <v-select
                  dense
                  outlined
                  :value="value.district_custom"
                  @input="$emit('input', {...value, district_custom: $event})"
                  :items="districtCustomItemsWithEmpty"
                  item-value="id"
                  item-text="value"
                  label="Район"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-select>
              </div>
              <div class="width-70 d-flex">
                <v-select
                  outlined
                  dense
                  :value="value.apartment_complex"
                  @input="$emit('input', {...value, apartment_complex: $event})"
                  :items="apartmentComplexItemsWithEmpty"
                  item-value="id"
                  item-text="value"
                  label="ЖК"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-select>
              </div>
              <div class="width-70 d-flex">
                <v-select
                  outlined
                  dense
                  :value="value.builder"
                  @input="$emit('input', {...value, builder: $event})"
                  :items="builderItemsWithEmpty"
                  item-value="id"
                  item-text="value"
                  label="Застройщик"
                  :disabled="!!value.mainBuildingAddress.id"
                ></v-select>
              </div>
              <div>
                <p class="v-alert">Если вы нашли несоответствие в информации о доме, напишите нам об этом</p>
                <v-textarea
                  outlined
                  :value="defaultDesc"
                  @input="$emit('update:defaultDesc', $event)"
                  label="Комментарий"
                ></v-textarea>
              </div>
              <div>
                <v-textarea
                  outlined
                  placeholder="Эта информация будет доступна только нашим сотрудникам"
                  :value="serviceDesc"
                  @input="$emit('update:serviceDesc', $event)"
                  label="Информация для агентов"
                ></v-textarea>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <UploadBuildingImage
                v-model="value.mainBuildingImage"
                url="/image/building"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputed'
import image from '@/components/mixins/image'
import validationErrors from '@/components/mixins/validationErrors'
import ImageView from '@/components/views/Common/ImageView'
import UploadBuildingImage
  from '@/components/views/Account/MyAdvert/Detail/BuildingSection/BuildingInfo/UploadBuildingImage'

export default {
  mixins: [serviceDataComputeds, image, validationErrors],
  name: 'BuildingInfo',
  components: { UploadBuildingImage, ImageView },
  props: {
    value: Object,
    show: Boolean,
    defaultDesc: String,
    serviceDesc: String,
    isLoading: Boolean
  },
  inject: ['$v'],
  methods: {
    handleStreetNameChange (data) {
      let value = this.value
      value.mainBuildingAddress.street.name = data
      this.$emit('input', value)
    },
    handleBuildingNumberChange (data) {
      let value = this.value
      value.mainBuildingAddress.building_number = data
      this.$emit('input', value)
    }
  }
}
</script>

<style>
</style>
