<template>
  <div>
    <AddressSearch
      v-if="value.mainBuildingAddress"
      :address="value.mainBuildingAddress"
      @update:address="handleUpdateAddress($event)"
      :show.sync="show"
    />
    <BuildingInfo
      :value="value"
      @input="$emit('input', $event)"
      :default-desc="defaultDesc"
      @update:defaultDesc="$emit('update:defaultDesc', $event)"
      :service-desc="serviceDesc"
      @update:serviceDesc="$emit('update:serviceDesc', $event)"
      :show="show"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import image from '@/components/mixins/image'
import serviceDataComputeds from '@/components/mixins/serviceDataComputed'
import Building from '@/components/structures/building'
import AddressSearch from '@/components/views/Account/MyAdvert/Detail/BuildingSection/AddressSearch'
import BuildingInfo from '@/components/views/Account/MyAdvert/Detail/BuildingSection/BuildingInfo'
import loading from '@/components/mixins/loading'

export default {
  mixins: [formatters, image, serviceDataComputeds, loading],
  name: 'BuildingSection',
  components: { BuildingInfo, AddressSearch },
  props: {
    value: Object,
    defaultDesc: String,
    serviceDesc: String
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    async handleUpdateAddress (data) {
      if (!this.isLoading) {
        this.isLoading = true
        let response = null
        if (data.building_id) {
          response = await this.$store.dispatch('server/get', { url: '/building/info/' + data.building_id })
        } else {
          response = new Building()
        }
        if (response) {
          this.$emit('input', response)
        }
        this.isLoading = false
      }
      return true
    }
  }
}
</script>

<style>
  .photo-building {
    width: 100%;
  }
  .photo-building img {
    max-width: 100%;
    width: auto;
  }

</style>
