<template>
  <div class="section section-main-100 ">
    <v-container class="base-content pt-5">
      <div class="d-flex justify-space-between flex-wrap pt-5">
        <div class="title">Мои обявления</div>
        <div>
          <router-link :to="{name:'myAdvertsDetail'}">
            <v-btn color="primary">Разместить объявление</v-btn>
          </router-link>
        </div>
      </div>
      <List class="pt-5"/>
    </v-container>
  </div>
</template>

<script>
import List from '@/components/views/Account/MyAdvert/List'
import Detail from '@/components/views/Account/MyAdvert/Detail'

export default {
  name: 'MyAdverts',
  components: { Detail, List }
}

</script>

<style scoped>

</style>
