import { mask } from '@titou10/v-mask'
import { cleanClone } from '@/components/utils/common'

export default {
  directives: {
    mask
  },
  props: {
    value: Object,
    canRemove: Boolean,
    mask: String,
    inputClass: String,
    index: Number
  },
  data () {
    return {
      phone: cleanClone(this.value),
      maskedPhone: cleanClone(this.value.phone.phone),
      clearValue: null
    }
  },
  watch: {
    async clearValue () {
      this.phone.phone.phone = this.clearValue
    },
    value: {
      handler () {
        this.phone = cleanClone(this.value)
      },
      deep: true
    }
  },
  methods: {
    handleInput (data) {
      this.maskedPhone = data
    }
  }
}
