<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          @update:options="handleOptionsUpdate($event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1 tableListAdvert"
          :footer-props="{itemsPerPageOptions:  [20,100]}"
          multi-sort
          dense
        >

          <template v-slot:item="{ item, index }">
            <tr
              @click="detail(item.id)"
            >
              <td>{{item.id}}</td>
              <td>{{item.building.mainBuildingAddress.street.name}} {{item.building.mainBuildingAddress.building_number}}</td>
              <td>{{item.room_count}}</td>
              <td>{{item.area_common}}</td>
              <td>{{formatValue('advert.flat_type', item.flat_type)}}</td>
              <td>{{item.floor}}</td>
              <td>{{item.building.total_floors}}</td>
              <td>{{formatValue('building.building_material', item.building.building_material)}}
              <td>{{item.building.build_year}}</td>
              <td>{{formatValue('advert.flat_status', item.flat_status)}}</td>
              <td>{{formatPrice(item.price)}}</td>
              <td>{{timestampToDate(item.created_at)}}</td>
              <td>{{item.is_published ? 'Да' : 'Нет'}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data () {
    return {
      headers: headers
    }
  }
}
</script>

<style scoped>
  .phone {
    min-width: 90px;
  }

</style>
