import Phone from './phone'

class AdvertPhone {
  constructor () {
    this.id = null
    this.phone = new Phone()
    this.is_main = false
    this.description = null
  }
}

export default AdvertPhone
