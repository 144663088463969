<template>
  <div class="section-main-100">
    <!--todo: сделать прелоадер по центру экрана-->
    <v-progress-circular
      v-if="isLoading"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div v-else>
      <!--todo: стилизовать блок с ошибкой-->
      <div v-if="!detail">
        Что-то пошло не так
      </div>
      <v-container v-else>
        <v-row>
          <v-col
            cols="10"
            md="9"
          >
            <h1 class="mb-2">{{detail.id ? 'Объявление Id: ' + detail.id : 'Новое объявление'}}</h1>
            <v-alert
              v-if="detail.is_published"
              border="left"
              type="success"
              elevation="2"
              dismissible
            >
              Ваше объявление успешно прошло модерацию и опубликовано
            </v-alert>
            <v-alert
              v-else
              border="left"
              type="warning"
              elevation="2"
              dismissible
            >
              Ваше объявление будет опубликованно после модерации
            </v-alert>
            <SellerInfo
              :owner-status-advert.sync="detail.owner_status_local"
              :advert-phones="detail.advertPhones"
              @update:advertPhones="handleAdvertPhonesUpdate($event)"
            />
            <ImageUploader
              v-model="detail.advertImages"
              url="/image/real"
              :max-photo-count="30"
            />
            <AdvertInfo
              :flat-type.sync="detail.flat_type"
              :room-count.sync="detail.room_count"
              :floor.sync="detail.floor"
              :area-common.sync="detail.area_common"
              :area-kitchen.sync="detail.area_kitchen"
              :area-living.sync="detail.area_living"
              :planing-type.sync="detail.planing_type"
              :flat-status.sync="detail.flat_status"
              :description-public.sync="detail.description_public"
            />
            <BuildingSection
              v-model="detail.building"
              :service-desc.sync="detail.description_service"
              :default-desc.sync="detail.description_default"
            />
            <Price
              :price.sync="detail.price"
              :error-messages="checkErrors('detail.price')"
            />
            <Save
              :loading="isSavingData"
              @save="handleSave"
              @delete="handleDelete"
            />
          </v-col>
          <v-col
            cols="2"
            md="3"
          >
            <ChartAdvert
              :advert="detail"
              :profile="$store.getters['auth/getProfile']"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import formatters from '@/components/mixins/formatters'
import ChartAdvert from './Detail/ChartAdvert'
import SellerInfo from './Detail/SellerInfo'
import ImageUploader from './Detail/ImageUploader'
import AdvertInfo from './Detail/AdvertInfo'
import Save from './Detail/Save'
import Price from './Detail/Price'
import BuildingSection from '@/components/views/Account/MyAdvert/Detail/BuildingSection'
import { minLength, minValue, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import { cleanClone } from '@/components/utils/common'

export default {
  mixins: [detail, formatters, validationErrors],
  name: 'Detail',
  components: { BuildingSection, Price, Save, AdvertInfo, ImageUploader, SellerInfo, ChartAdvert },
  data () {
    return {
      url: '/account'
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      flat_type: {
        required
      },
      room_count: {
        required,
        minValue: minValue(1)
      },
      floor: {
        required,
        minValue: minValue(1)
      },
      area_common: {
        required,
        minValue: minValue(1)
      },
      price: {
        required,
        minValue: minValue(1)
      },
      advertPhones: {
        required,
        $each: {
          phone: {
            phone: {
              required,
              minLength: minLength(11)
            }
          }
        }
      },
      building: {
        mainBuildingAddress: {
          building_number: {
            required
          },
          street: {
            name: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    handleAdvertPhonesUpdate (data) {
      this.detail.advertPhones = cleanClone(data)
    }
  }
}
</script>

<style>
</style>
