<template>
  <div class="text-center mt-1 d-flex flex-column-reverse">
    <div class="row-images-thumb">
      <v-card
        outlined
        width="150px"
        height="150px"
        class="item-image-thumb d-flex justify-center align-center pa-0 mt-5"
        v-for="(image, index) in uploadedFiles"
        :key="index"
      >
        <v-card-text class="pa-0 align-center">
          <div class="control-image-thumb">
            <v-btn
              icon
              @click="handleRemove(index)"
              color="red"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
          <v-dialog max-width="400">
            <template v-slot:activator="{ on }">
              <ImageView
                :on="on"
                max-width="470"
                max-height="470"
                :src="thumbSrc(image.image, true)"
              />
            </template>
            <div class="dialog">
              <v-card>
                <ImageView
                  max-width="400"
                  min-height="200"
                  :src="imageSrc(image.image)"
                />
              </v-card>
            </div>
          </v-dialog>
        </v-card-text>
      </v-card>
    </div>
    <div class="image-uploader rounded-xl">
      <input
        type="file"
        multiple
        accept="image/*"
        class="input-file"
        :name="uploadFieldName"
        :disabled="isUploading || !canUploadImages"
        @change="filesChange($event.target.name, $event)"
      >
      <div v-if="isUploading">
        <v-progress-linear
          color="primary"
          indeterminate
        >
        </v-progress-linear>
        <p style="color:#1976d2">Подождите фото загружаются</p>
      </div>
      <div v-else>
        <p class="ma-0 black--text body-2">
          <v-icon
            color="black"
            class="mr-1s"
          >mdi-camera-outline
          </v-icon>
          ЗАГРУЗИТЬ ФОТО КВАРТИРЫ ДО 30 ШТ
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import image from '@/components/mixins/image'
import ImageView from '@/components/views/Common/ImageView'
import imageUpload from '@/components/mixins/imageUpload'

export default {
  components: { ImageView },
  mixins: [formatters, image, imageUpload],
  name: 'ImageUploader',
  data () {
    return {
      imageObjectName: 'relatedImage'
    }
  }
}
</script>

<style>
  .image-uploader {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 28px 24px;
    border-radius: 3px;
    border: 1px solid #000000;
    max-width: 366px;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  .dialog {
    position: relative;
  }
  .dialog img {
    height: 100%;
  }
</style>
