<template>
  <div class="chart mobile-chart">
    <div class="chart-block mobile-chart-block">
      <v-card
        outlined
      >
        <v-card-text>
          <p><b>Добавьте больше данных для карточки объявления</b></p>
          <div class="text-center pt-5 pb-5 mobile-progress-circular">
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="18"
              :value="sum"
              color="amber"
            >
              <div class="percentage-size">{{ sum }}%
              </div>
            </v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
      <v-row
        v-if="sum < 100"
        class="fast-link"
      >
        <v-col cols="12">
          <v-alert
            color="blue-grey lighten-5"
            dense
            icon="mdi-alert"
          ><p style="font-size: 14px">
            Ваше объявление пока заполнено не целиком. Добавьте недостающие сведения –
            и люди будут охотнее
            выбирать именно вас. </p>
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { iterateOnObject } from '@/components/utils/common'

export default {
  name: 'ChartAdvert',
  props: {
    profile: Object,
    advert: Object
  },
  data: () => ({
    sum: 0,
    chart: {
      profile: {
        username: 1,
        userPhones: 1
      },
      advert: {
        owner_status_local: 1,
        advertImages: 20,
        flat_type: 5,
        room_count: 3,
        floor: 3,
        area_common: 4,
        area_kitchen: 3,
        area_living: 3,
        planing_type: 3,
        flat_status: 4,
        description_public: 5,
        // exclusive_status: 3,
        building: {
          building_type: 2,
          total_floors: 2,
          build_year: 1,
          building_material: 1,
          district_custom: 2,
          apartment_complex: 1,
          builder: 1,
          description_service: 5,
          mainBuildingImage: 17
        },
        price: 13
      }
    }
  }),
  computed: {
    iterObject () {
      return { profile: this.profile, advert: this.advert }
    }
  },
  watch: {
    advert: {
      handler: 'calculateSum',
      deep: true,
      immediate: true
    },
    profile: {
      handler: 'calculateSum',
      deep: true,
      immediate: true
    }
  },
  methods: {
    calculateSum () {
      this.sum = iterateOnObject(this.chart, this.iterObject)
    }
  }
}
</script>

<style scoped>
  @media (max-width: 800px) and (min-width: 200px) {
    .fast-link {
      display: none
    }
    .mobile-chart-block {
      width: min-content;
      height: min-content;
    }
    .mobile-chart {
      position: fixed;
      width: 135px !important;
    }
  }
  .chart {
    position: fixed;
    width: 300px;
    right: 10px;
  }
  .chart-block {
    position: relative;
  }
  .percentage-size {
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
</style>
