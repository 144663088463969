import Image from '@/components/structures/image'

class RelatedImage {
  constructor (image) {
    this.id = null
    if (image) {
      this.image = image
    } else {
      this.image = new Image()
    }
  }
}

export default RelatedImage
