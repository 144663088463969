export default {
  props: {
    value: Array
  },
  data () {
    return {
      localPhones: this.value
    }
  },
  watch: {
    value: {
      handler (val) {
        this.localPhones = val
      },
      deep: true
    }
  },
  methods: {
    handlePhoneChange (index, data) {
      if (index !== null) {
        this.localPhones[index] = data
      } else {
        this.localPhones.push(data)
      }
      this.$emit('input', this.localPhones)
    },
    handleRemovePhone (index) {
      this.localPhones.splice(index, 1)
    }
  }
}
