<template>
  <v-card
    v-if="!isLoadingProfile && !isLoadingServiceData"
    outlined
  >
    <v-card-text>
      <div
        class="seller-info"
      >
        <h2 class="mb-5">Данные продавца</h2>
        <div class="pt-5 pb-10">
          <h1>{{profile.username}}</h1>
          <h2
            v-for="userPhone in profile.userPhones"
            :key="userPhone.id"
          >
            {{maskValue(userPhone.phone.phone, phoneMask)}}
          </h2>
        </div>
      </div>
      <AdditionPhoneList
        :value="advertPhones"
        @input="$emit('update:advertPhones', $event)"
      />
      <div>
        <div>
          <p class="subtitle-1">Укажите какое отношение Вы имеете к данному объекту</p>
        </div>
        <v-radio-group
          v-if="ownerStatusLocalItems"
          :value="ownerStatusAdvert"
          @change="handleOwnerStatusChange($event)"
          row
        >
          <v-radio
            v-for="status in ownerStatusLocalItems"
            :key="status.id"
            :label="status.value"
            :value="status.id"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-card-text>
  </v-card>
  <v-card
    v-else
    outlined
  >
    <v-card-text>
      <div
        class="seller-info"
      >
        <h2 class="mb-5">Данные продавца</h2>
        <div class="pt-5 pb-10">
          <v-text-field
            v-model="detail.username"
            required
            label="Имя"
            @focus="$event.target.select()"
          ></v-text-field>
        </div>
      </div>
      <AdditionPhoneList
        :value="advertPhones"
        @input="$emit('update:advertPhones', $event)"
      />
      <div>
        <div>
          <p class="subtitle-1">Укажите какое отношение Вы имеете к данному объекту</p>
        </div>
        <v-radio-group
          v-if="ownerStatusLocalItems"
          :value="ownerStatusAdvert"
          @change="handleOwnerStatusChange($event)"
          row
        >
          <v-radio
            v-for="status in ownerStatusLocalItems"
            :key="status.id"
            :label="status.value"
            :value="status.id"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AdditionPhoneList from './AdditionPhoneList'
import formatters from '@/components/mixins/formatters'
import phoneComputed from '@/components/mixins/phoneComputed'
import serviceDataComputed from '@/components/mixins/serviceDataComputed'

export default {
  mixins: [formatters, phoneComputed, serviceDataComputed],
  name: 'SellerInfo',
  components: { AdditionPhoneList },
  props: {
    ownerStatusAdvert: Number,
    advertPhones: Array
  },
  data: function () {
    return {
      profile: this.$store.getters['auth/getProfile'],
      detail: {
        username: ''
      }
    }
  },
  computed: {
    isLoadingProfile () {
      return !this.$store.getters['auth/getProfile']
    },
    isLoadingServiceData () {
      return !this.ownerStatusLocalItems
    }
  },
  watch: {
    isLoadingProfile () {
      this.handleDefaultAdvertPhones()
    },
    isLoadingServiceData () {
      if (!this.ownerStatusAdvert) {
        this.handleOwnerStatusChange(this.ownerStatusLocalItems[0].id)
      }
    }
  },
  methods: {
    handleDefaultAdvertPhones () {
      this.profile = this.$store.getters['auth/getProfile']
      let advertPhones = this.advertPhones
      if (!advertPhones.length) {
        advertPhones = this.profile.userPhones.map(userPhone => ({ ...userPhone, description: 'Владелец', id: null }))
      }
      this.$emit('update:advertPhones', advertPhones)
    },
    handleOwnerStatusChange (data) {
      this.$emit('update:ownerStatusAdvert', data)
    }
  },
  mounted () {
    if (!this.isLoadingProfile) {
      this.handleDefaultAdvertPhones()
    }
    if (!this.isLoadingServiceData) {
      this.handleOwnerStatusChange(this.ownerStatusLocalItems[0].id)
    }
  }
}
</script>

<style scoped>

</style>
